import strategy00 from '../assets/bg-1.jpeg'
import strategy01 from '../assets/bg-2.jpg'
import strategy02 from '../assets/bg-3.jpg'
import strategy10 from '../assets/about-hero.jpg'
import strategy11 from '../assets/our-strategy4.jpg'
import strategy12 from '../assets/our-strategy5.jpg'
import strategy20 from '../assets/our-strategy1.jpg'
import strategy21 from '../assets/our-strategy2.jpg'
import strategy22 from '../assets/our-strategy3.jpg'
import strategy30 from '../assets/our-strategy6.jpg'
import strategy31 from '../assets/our-strategy7.png'
import strategy32 from '../assets/our-strategy8.png'
import strategy40 from '../assets/our-strategy9.png'
import strategy41 from '../assets/our-strategy10.png'
import strategy42 from '../assets/our-strategy11.png'


import qualityPolicy from '../assets/certificate.jpg'
import performanceCert from '../assets/preformance-cert.jpg'
import appreciationLetter from '../assets/appreciation-letter.png'
import waraqaaGalatoomaa from '../assets/waraqaa-galatoomaa-cert.png'
import recognitionCert from '../assets/recognition-cert.png'
import iqnetCert1 from '../assets/IQNET-DQS-cert1.png'
import iqnetCert2 from '../assets/IQNET-DQS-cert2.png'
import yohannes from '../assets/yohannes.jpg'
import gosaye from '../assets/gosaye.jpg'
import CWCE from '../assets/cwce.svg'
import ECO from '../assets/eco.svg'
import Yooshin from '../assets/yooshin.svg'
import HongIK from '../assets/hongik.svg'
import sound from '../assets/sound.svg'
import STADIA from '../assets/stadia.svg'
import UNICONE from '../assets/unicone.svg'

import ERA from '../assets/era.svg'
import ORLB from '../assets/orlb.png'
import DCE from '../assets/dce.svg'
import CrossLand from '../assets/crossland.svg'
import AACRA from '../assets/aacra.svg'
import EWNRA from '../assets/ewnra.svg'
import AfroTsion from '../assets/afrotsion.svg'
import MCG from '../assets/mcg.svg'
import JPLANT from '../assets/jplant.svg'
import MARKAN from '../assets/markan.svg'
import OCC from '../assets/occ.svg'
import React, {useEffect, useState} from 'react'
import {BsCircleFill} from 'react-icons/bs'

import '../sass/about.scss'

const About = () => {

    const [bgSlide, changeBgSlide] = useState(0);
    const [strategySlide, changeStrategySlide] = useState(0);


    useEffect(() => {
        const bgSlideInterval = setInterval(() => {
            changeBgSlide(bgSlide => (bgSlide + 1) % 3)
        }, 5000);

        const strategySlideInterval = setInterval(() => {
            changeStrategySlide(strategySlide => (strategySlide + 1) % 5)
        }, 5000);

        return () => {
            clearInterval(bgSlideInterval)
            clearInterval(strategySlideInterval)
        }
    }, [])

    return (
        <main className='about-page'>
            <div className='hero d-flex flex-row align-items-center justify-content-between'>
                <div
                    className='content-container d-flex flex-row justify-content-center justify-content-lg-between align-items-center'>
                    <div className='slides-container d-flex flex-row col-10 col-lg-8'>
                        <div>
                            <div className='content'>
                                <h3>About G&Y</h3>
                                <p>
                                    G&Y Engineering Consult PLC is a leading provider of consultancy services in
                                    multiple engineering disciplines and project management. Exceptional value of work
                                    and unprecedented professional services reinforce our high quality engineering
                                    services we provide to public and private sector clients. Our expertise in design
                                    and construction supervision allows us to offer comprehensive work from commencement
                                    to delivery.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className='block col-lg-1 d-none d-lg-block'></div>
                </div>
            </div>

            <section className='founders'>
                <h2>Founders</h2>
                <article>
                    <hr/>
                    <div className='founders-list d-flex flex-column align-items-center flex-lg-row'>
                        <div className='paper'>
                            <img src={yohannes} alt='member 1'/>
                            <div className='description'>
                                <p className='head'>Eng. Yohannes Tsegaye</p>
                                <p className='info'>General Manager</p>
                                <div className='fields'>
                                    <p>Secretary</p>
                                    <p>Legal Advisor</p>
                                    <p>Financial Advisor</p>
                                    <p>Finance &amp; Human Resource Management Director</p>
                                </div>
                            </div>
                        </div>
                        <div className='paper'>
                            <img src={gosaye} alt='member 2'/>
                            <div className='description'>
                                <p className='head'>Eng. Gossaye Gizaw</p>
                                <p className='info'>Deputy General Manager</p>
                                <div className='fields'>
                                    <p>Project Formulation Director</p>
                                    <p>Design Director</p>
                                    <p>Contract Administration &amp; Supervision Director</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr/>
                    <div className='info'>
                        <p>
                            The founders of organization independently hold a wealth of knowledge and experience; they
                            have brought in the formation and operational process of the organization. It is this
                            individual knowledge of the personnel and overall wealth of our company which makes us first
                            choice of our clients.
                        </p>
                    </div>
                </article>
            </section>
            <section className='purpose d-flex flex-column justify-content-center'>
                <h2>Purpose</h2>
                <div className='card-list d-flex justify-content-between'>
                    <div className='card d-flex flex-column align-items-center'>
                        <h3>Mission</h3>
                        <p>
                            To provide practical professionalism, pioneering ingenuity and quality service which last in
                            the minds of our clients.
                        </p>
                    </div>
                    <div className='card d-flex flex-column align-items-center'>
                        <h3>Vision</h3>
                        <p>
                            To be the market leader and premier inter disciplinary engineering firm, providing
                            exceptional values in Ethiopia and Africa.
                        </p>
                    </div>
                    <div className='card d-flex flex-column align-items-center'>
                        <h3>Values</h3>
                        <p>
                            Integrity, client focus due diligence, commitment professionalism, accountability.
                        </p>
                    </div>
                </div>
            </section>

            <section className='our-strategy text-black'>
                <h2>Our Strategy</h2>

                <div className='slides'>
                    <div className={`slider-item ${strategySlide === 0 ? 'active' : ''}`}>
                        <article>
                            <aside className='img-grid'>
                                <img src={strategy00}
                                     alt="Two people working on an asphalt road with 2 trucks behind them"/>
                                <img src={strategy01}
                                     alt="A tractor pouring cement on a road that's under construction"/>
                                <img className='stretched' src={strategy02}
                                     alt="Two roller tractors are pressing a fresh asphalt road"/>
                            </aside>
                            <div className='description'>
                                <h3>Our Experience</h3>
                                <div>
                                    <p>
                                        Our experience with different Engineering undertakings has allowed us to design
                                        and implement projects with high quality theoretical and practical expertise, a
                                        proficiency injected into every project.
                                        It is this individual knowledge of the personnel and overall wealth of our
                                        company which makes us the first choice of our clients.
                                    </p>
                                </div>
                            </div>
                        </article>
                    </div>
                    <div className={`slider-item ${strategySlide === 1 ? 'active' : ''}`}>
                        <article>
                            <aside className='img-grid'>
                                <img src={strategy10}
                                     alt="Two people working on an asphalt road with 2 trucks behind them"/>
                                <img src={strategy11}
                                     alt="A tractor pouring cement on a road that's under construction"/>
                                <img className='stretched' src={strategy12}
                                     alt="Two roller tractors are pressing a fresh asphalt road"/>
                            </aside>
                            <div className='description'>
                                <h3>Client Requirements</h3>
                                <div>
                                    <p>
                                        Our total consultancy package is driven by our multi-disciplinary approach to
                                        meet the demand of our clients.
                                    </p>
                                    <p>
                                        Our "word" is our value, from start to finish we deliver what we promise. This
                                        is our clients advantage, a working experience that's geared for delivering on
                                        the clients vision.
                                    </p>
                                </div>
                            </div>
                        </article>
                    </div>
                    <div className={`slider-item ${strategySlide === 2 ? 'active' : ''}`}>
                        <article>
                            <aside className='img-grid'>
                                <img src={strategy20}
                                     alt="Two people working on an asphalt road with 2 trucks behind them"/>
                                <img src={strategy21}
                                     alt="A tractor pouring cement on a road that's under construction"/>
                                <img className='stretched' src={strategy22}
                                     alt="Two roller tractors are pressing a fresh asphalt road"/>
                            </aside>
                            <div className='description'>
                                <h3>Value Engineering</h3>
                                <div>
                                    <p>
                                        Our Engineering strength covers an extensive list of services, divided into
                                        feasibility study, conceptual design project implementation and evaluation.
                                    </p>
                                    <p>
                                        Throughout each step we add value by working with the client to insure they are
                                        informed and aware of each phase of the project.
                                    </p>
                                </div>
                            </div>
                        </article>
                    </div>
                    <div className={`slider-item ${strategySlide === 3 ? 'active' : ''}`}>
                        <article>
                            <aside className='img-grid'>
                                <img src={strategy32}
                                     alt="Two people working on an asphalt road with 2 trucks behind them"/>
                                <img src={strategy31}
                                     alt="A tractor pouring cement on a road that's under construction"/>
                                <img className='stretched' src={strategy30}
                                     alt="Two roller tractors are pressing a fresh asphalt road"/>
                            </aside>
                            <div className='description'>
                                <h3>Project Management</h3>
                                <div>
                                    <p>
                                        Management is professional art we have perfected as we have grown.
                                    </p>
                                    <p>
                                        To ensure actions are in line with projections and resources, we implement a
                                        strategy particularly adapted to the needs and objectives of the project.
                                    </p>
                                </div>
                            </div>
                        </article>
                    </div>
                    <div className={`slider-item ${strategySlide === 4 ? 'active' : ''}`}>
                        <article>
                            <aside className='img-grid'>
                                <img src={strategy40}
                                     alt="Two people working on an asphalt road with 2 trucks behind them"/>
                                <img src={strategy41}
                                     alt="A tractor pouring cement on a road that's under construction"/>
                                <img className='stretched' src={strategy42}
                                     alt="Two roller tractors are pressing a fresh asphalt road"/>
                            </aside>
                            <div className='description'>
                                <h3>Commitment to Excellence</h3>
                                <div>
                                    <p>
                                        We set the expectations we can achieve based on the vision we set with our
                                        clients. The results are achieved by consciously backing the progress.
                                    </p>
                                    <p>
                                        We serve our clients with great care and value, committed to accountability. To
                                        achieve success, we apply consistent practices and achievement markers.
                                    </p>
                                </div>
                            </div>
                        </article>
                    </div>

                </div>
                <div className='indicators col-9 col-lg-6 d-flex flex-row justify-content-evenly align-items-center'>
                    <div className={`dot ${strategySlide === 0 ? 'fill' : ''}`} onClick={() => changeStrategySlide(0)}
                         size={15}/>
                    <div className={`dot ${strategySlide === 1 ? 'fill' : ''}`} onClick={() => changeStrategySlide(1)}
                         size={15}/>
                    <div className={`dot ${strategySlide === 2 ? 'fill' : ''}`} onClick={() => changeStrategySlide(2)}
                         size={15}/>
                    <div className={`dot ${strategySlide === 3 ? 'fill' : ''}`} onClick={() => changeStrategySlide(3)}
                         size={15}/>
                    <div className={`dot ${strategySlide === 4 ? 'fill' : ''}`} onClick={() => changeStrategySlide(4)}
                         size={15}/>
                </div>
            </section>

            <section className='quality-policy'>
                <h2>Quality Policy</h2>
                <article>
                    <p>
                        We develop solutions that are feasible, economical and environmentally sustainable to make sure
                        we meet the needs of our Clients.
                    </p>
                    <div>
                        <img src={qualityPolicy} alt='Quality Policy'/>
                    </div>
                </article>

                <h2>Outstanding Performance Certificate</h2>
                <article>
                    <p>
                        Oromia Road and Logistics Bureau 2020/2021
                    </p>
                    <div>
                        <img src={performanceCert} alt='Performance Certificate'/>
                    </div>
                </article>

                <h2>Appreciation Letter</h2>
                <article>
                    <p>
                        Oromia Region State 28/05/2015 (E.C)
                    </p>
                    <div>
                        <img src={appreciationLetter} alt='Appreciation Letter'/>
                    </div>
                </article>

                <h2>Thank You Letter</h2>
                <article>
                    <div>
                        <img src={waraqaaGalatoomaa} alt='Thank You Letter'/>
                    </div>
                </article>

                <h2>Certificate of Recognition</h2>
                <article>
                    <p>
                        Ande Mamma 2021/2022
                    </p>
                    <div>
                        <img src={recognitionCert} alt='Certificate of Recognition'/>
                    </div>
                </article>

                <h2>Certificate of Recognition</h2>
                <article>
                    <p>
                        IQNET Certificate Valid From 2023-03-20 To 2026-03-19
                    </p>
                    <div>
                        <img src={iqnetCert1} alt='IQNET DQS Certificate 1'/>
                    </div>
                </article>

                <h2>Certificate of Recognition</h2>
                <article>
                    <p>
                        DQS Certificate Valid From 2023-03-20 To 2026-03-19
                    </p>
                    <div>
                        <img src={iqnetCert2} alt='IQNET DQS Certificate 2'/>
                    </div>
                </article>
            </section>

            <section className='clients-and-partners'>
                <div className='partners'>
                    <h3>Our Partners</h3>
                    <div className='partners-grid'>
                        <img src={CWCE} alt='cwce'/>
                        <img src={ECO} alt='eco'/>
                        <img src={Yooshin} alt='yooshin'/>
                        <img className='hong-ik' src={HongIK} alt='hong-ik'/>
                        <img src={sound} alt='sound'/>
                        <img className='stadia' src={STADIA} alt='stadia'/>
                        <img className='unicone' src={UNICONE} alt='unicone'/>
                    </div>
                </div>
                <div className='clients'>
                    <h3>Our Clients</h3>
                    <div className='clients-grid'>
                        <img src={ERA} alt='era'/>
                        <img src={ORLB} alt='orlb'/>
                        <img src={DCE} alt='dce'/>
                        <img src={CrossLand} alt='crossland'/>
                        <img src={AACRA} alt='aacra'/>
                        <img src={EWNRA} alt='ewnra'/>
                        <img src={AfroTsion} alt='afrotsion'/>
                        <img src={MCG} alt='mcg'/>
                        <img src={JPLANT} alt='jplant'/>
                        <img className='markan' src={MARKAN} alt='markan'/>
                        <img src={OCC} alt='occ'/>
                    </div>
                </div>
            </section>

        </main>
    )
}

export default About
