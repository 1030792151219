import React from 'react'
import { useParams } from 'react-router-dom'
import { useQuery, gql } from "@apollo/client"
import Markdown from 'react-markdown'
import '../sass/news-detail.scss'

const ARTICLE = gql`
  query GetArticle($id: ID!) {
    article(id: $id) {
      data {
        id
        attributes {
          title
          body
          publishedAt
          coverImage {
          data {
            attributes {
              name,
              formats
            }
          }
        }
          categories {
            data {
              id
              attributes {
                name
              }
            }
          }
        }
      }
    }
  }
`

function NewsDetail() {
  const { id } = useParams()
  const { data, loading, error } = useQuery(ARTICLE, {
    variables: { id: id }
  })
  if (loading) return <p>Loading Articles...</p>
  if (error) return <p>Error: {error.message}</p>

  const coverImageURL = data?.article.data.attributes?.coverImage.data?.attributes.formats.medium.url
  console.log(coverImageURL);
  const publishedDate = new Date(data.article.data.attributes.publishedAt)
  const dateTimeFormat = new Intl.DateTimeFormat('en-GB', {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  });
  const publishedAt = dateTimeFormat.format(publishedDate)
  return (
    <main className='news-detail w-50 mx-auto'>
      <div className='news-detail-category'>
        {data.article.data.attributes.categories.data.map(category => {
          return (
            <span key={category.id} className='news-detail-category-text'>
              {category.attributes.name}
            </span>
          )
        })}
        <h5>{publishedAt.toUpperCase()}</h5>
      </div>

      <h1 className="my-5">{data.article.data.attributes.title.toUpperCase()}</h1>
      <div className='text-center'>
        {
          coverImageURL &&
          <img
            src={process.env.REACT_APP_STRAPI_URL + coverImageURL}
            alt={data.article.data.attributes.coverImage.data.attributes.name} />
        }
      </div>

      <Markdown className="mt-5">{data.article.data.attributes.body}</Markdown>
    </main>
  )
}

export default NewsDetail
