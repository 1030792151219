import React from 'react';
import {Link, Outlet} from 'react-router-dom';
import {useQuery, gql} from "@apollo/client";
import * as ROUTES from '../constants/routes'
import '../sass/news-header.scss';

const CATEGORIES = gql`
  query GetCategories  {
    categories {
        data {
            id
            attributes {
                name
            }
        }
    }
  }
`

function NewsHeader() {
    const {data, loading, error} = useQuery(CATEGORIES)

    if (loading) return <p>Loading Articles...</p>
    if (error) return <p>Error: {error.message}</p>

    return (
        <main className='news-header'>
            <section className='title d-flex justify-content-between'>
                <div className='title-left col-12 col-lg-6 text-left'>
                    <h1>G&Y News</h1>
                </div>
                <div className='title-right col-1'>
                </div>
            </section>
            <nav className='news-header-nav'>
                {
                    data.categories.data.map(category => {
                        return (
                            <Link key={category.id} to={ROUTES.NEWS_CATEGORY + `/${category.id}`}
                                  className='category-text'>
                                {category.attributes.name}
                            </Link>
                        )
                    })
                }
            </nav>
            <Outlet/>
        </main>
    )
}

export default NewsHeader
