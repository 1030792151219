import { BrowserRouter, Routes, Route } from "react-router-dom";
import SharedLayout from "./components/SharedLayout";
import Home from "./components/Home";
import About from "./components/About";
import Projects from "./components/Projects";
import Services from "./components/Services";
import News from "./components/News";
import NewsDetail from "./components/NewsDetail";
import NewsCategory from "./components/NewsCategory";
import NewsHeader from "./components/NewsHeader";
import Contact from "./components/Contact";
import Error from "./components/Error";
import * as ROUTES from "./constants/routes";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  createHttpLink,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import "./sass/app.scss";

// Apollo Client
const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = process.env.REACT_APP_STRAPI_TOKEN;
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

const client = new ApolloClient({
  link: authLink.concat(
    createHttpLink({ uri: process.env.REACT_APP_STRAPI_URL + "/graphql" }),
  ),
  cache: new InMemoryCache(),
});

function App() {
  return (
    <BrowserRouter>
      <ApolloProvider client={client}>
        <Routes>
          <Route path={ROUTES.HOME} element={<SharedLayout />}>
            <Route index element={<Home />} />
            <Route path={ROUTES.ABOUT} element={<About />} />
            <Route path={ROUTES.PROJECTS} element={<Projects />} />
            <Route path={ROUTES.SERVICES} element={<Services />} />
            <Route path={ROUTES.NEWS} element={<NewsHeader />}>
              <Route index element={<News />} />
              <Route
                path={ROUTES.NEWS_CATEGORY + "/:id"}
                element={<NewsCategory />}
              />
            </Route>
            <Route
              path={ROUTES.NEWS + "/" + ROUTES.NEWS_DETAIL + "/:id"}
              element={<NewsDetail />}
            />
            <Route path={ROUTES.CONTACT} element={<Contact />} />
            <Route path={ROUTES.ERROR} element={<Error />} />
          </Route>
        </Routes>
      </ApolloProvider>
    </BrowserRouter>
  );
}

export default App;
