import {Link} from "react-router-dom"
import {useQuery, gql} from "@apollo/client"
import * as ROUTES from '../constants/routes'
import Markdown from "react-markdown"
import "../sass/news.scss"

const ARTICLES = gql`
query GetArticles {
    articles {
        data {
            id
            attributes {
                title
                body
              	categories {
                  data {
                    id
                    attributes {
                      name
                    }
                  }
                }
              }
            }
        }
    }
`

function News() {
    const {data, loading, error} = useQuery(ARTICLES)

    if (loading) return <p>Loading Articles...</p>
    if (error) return <p>Error: {error.message}</p>


    return (
        <>
            <main className="news">
                {data.articles.data.map(article => (
                    <div key={article.id} className="news-article">
                        <h3 className="mb-3">{article.attributes.title}</h3>
                        <small>{article.attributes.categories.data.map(category => {
                            return <span key={category.id}
                                         className='news-article-category me-5'>{category.attributes.name}</span>
                        })}</small>
                        <Markdown className="mt-5">{article.attributes.body.substring(0, 500) + "..."}</Markdown>
                        <Link to={ROUTES.NEWS_DETAIL + `/${article.id}`} className='news-read-more-button'>Read
                            More</Link>
                    </div>
                ))}
            </main>
        </>
    )
}

export default News
